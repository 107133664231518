import { t } from "@lingui/core/macro"
import { Trans } from "@lingui/react/macro"
import {
  Anchor,
  Center,
  Container,
  Group,
  Image,
  SimpleGrid,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconAward,
  IconBan,
  IconCheck,
  IconConfetti,
  IconFiles,
  IconHeart,
  IconLifebuoy,
  IconPlusEqual,
  IconUsersGroup,
  IconWand,
} from "@tabler/icons-react"
import { useState, type ElementType, type ReactNode } from "react"
import { currentTaxYear } from "~/app/tax-years/tax-years"
import { PageContainer, PageWrapper } from "./_components"
import { IconMapleLeaf } from "./_IconMapleLeaf"
import { getSrc } from "./_utils"
import { getWebsiteHref, WebsiteLink } from "./_WebsiteLink"

export const Page = ({ currentPath }: { currentPath: string }) => (
  <PageWrapper currentPath={currentPath} pageContainer={false}>
    <PageContents />
  </PageWrapper>
)

export const PageContents = () => (
  <>
    <PageContainer>
      <Title order={2} ta="center" lh="3rem" fz="3rem" fw={600} mb="md">
        <Trans>Maximum refund, minimal stress</Trans>
      </Title>
      <Group wrap="nowrap" mb="4rem" gap="xs" justify="center">
        <Text ta="center" fz="xl" fw={500}>
          <Trans>
            A better way to file from the people who know how to build simple tax software
          </Trans>
        </Text>
      </Group>
    </PageContainer>

    <HeroImage />
    <Highlights />
    <FeaturesGrid />
  </>
)

const HeroImage = () => {
  const [hovered, setHovered] = useState(false)
  const [mouseDown, setMouseDown] = useState(false)

  const isMobile = useMediaQuery("(max-width: 48em)")

  return (
    <>
      <PageContainer>
        {isMobile ? (
          <picture>
            <source srcSet={getSrc("/images/hero_mobile.webp")} type="image/webp" />
            <Image
              src={getSrc("/images/hero_mobile.png")}
              alt={t`Better Tax screenshot`}
              style={{
                aspectRatio: "0.9 / 1",
                objectPosition: "top",
              }}
            />
          </picture>
        ) : (
          <Anchor
            href={getWebsiteHref(`/${currentTaxYear}`)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => {
              setHovered(false)
              setMouseDown(false)
            }}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => setMouseDown(false)}
          >
            <Image
              src={getSrc("/images/hero.png")}
              alt={t`Better Tax screenshot`}
              style={{
                aspectRatio: "3 / 1",
                objectPosition: "top",
                position: "relative",
                top: "4px",
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
                boxShadow: "0 0 0 1px var(--mantine-color-gray-2), var(--mantine-shadow-lg)",
                transform: hovered ? (mouseDown ? "scale(0.98)" : "scale(0.99)") : "scale(1)",
                transition: "transform 0.3s ease",
              }}
            />
          </Anchor>
        )}
      </PageContainer>
      <Space
        h="4rem"
        bg="white"
        style={{
          position: "relative",
          zIndex: 2,
          borderTop: "1px solid var(--mantine-color-gray-2)",
        }}
      />
    </>
  )
}

const Highlights = () => (
  <PageContainer>
    <SimpleGrid cols={{ base: 1, sm: 3 }} mb="xl">
      <Center ta="center" py="xl">
        <Group wrap="nowrap">
          <ThemeIcon variant="light" color="green" size="xl" radius="xl">
            <IconCheck stroke={3} />
          </ThemeIcon>
          <Stack gap={0}>
            <Text fw={600} fz="lg" c="dimmed">
              <Trans>CRA certified</Trans>
            </Text>
            <Text fw={600} fz="lg" c="dimmed">
              <Trans>tax software</Trans>
            </Text>
          </Stack>
        </Group>
      </Center>
      <Center ta="center" py="xl" bg="var(--mantine-color-gray-0)" style={{ borderRadius: 90 }}>
        <Stack gap={0}>
          <WebsiteLink fw={600} fz="xl" c="black" href="/pricing">
            <Trans>Pay what you want</Trans>
          </WebsiteLink>
          <Text fw={600} c="dimmed">
            <Trans>Yes, even $0. No catch.</Trans>
          </Text>
        </Stack>
      </Center>
      <Center ta="center" py="xl">
        <Group wrap="nowrap">
          <ThemeIcon variant="light" color="red" size="xl" radius="xl">
            <IconMapleLeaf size={24} />
          </ThemeIcon>
          <Text fw={600} fz="lg" c="dimmed">
            <Trans>
              Made by three
              <br />
              friendly Canadians
            </Trans>
          </Text>
        </Group>
      </Center>
    </SimpleGrid>
  </PageContainer>
)

const featureData = [
  {
    icon: IconWand,
    title: <Trans>Auto-fill your return</Trans>,
    description: (
      <Trans>
        Better Tax can automatically fill in your return with information, like your T4 slip,
        directly from the CRA.
      </Trans>
    ),
  },
  {
    icon: IconPlusEqual,
    title: <Trans>Refund optimizer</Trans>,
    description: (
      <Trans>
        We optimize 100+ things on your return to maximize your refund—including donations, medical
        expenses, pension income, and more.
      </Trans>
    ),
  },
  {
    icon: IconUsersGroup,
    title: <Trans>Family-friendly</Trans>,
    description: (
      <Trans>
        Better Tax is fun for the whole family. We help you share amounts between family members so
        you don't miss a single credit or deduction.
      </Trans>
    ),
  },
  {
    icon: IconLifebuoy,
    title: <Trans>Human support</Trans>,
    description: (
      <Trans>
        We believe everyone should be able to file their own return. Have a question? We're here to
        help.
      </Trans>
    ),
  },
  {
    icon: IconFiles,
    title: <Trans>Your data, your choice</Trans>,
    description: (
      <Trans>
        Export or delete your data at any time. Store your data in the cloud or{" "}
        <WebsiteLink href="/local">in a file on your own device</WebsiteLink>. Either way, your data
        is yours.
      </Trans>
    ),
  },

  {
    icon: IconBan,
    title: <Trans>No nonsense</Trans>,
    description: (
      <Trans>
        There are no ads, annoying upgrade prompts, dark patterns, or privacy-invasive trackers.
      </Trans>
    ),
  },
  {
    icon: IconHeart,
    title: <Trans>Crafted with care</Trans>,
    description: (
      <Trans>
        We're a <WebsiteLink href="/about">small team of three</WebsiteLink> with over a decade of
        tax software experience. We're in this to make a difference.
      </Trans>
    ),
  },
  {
    icon: IconAward,
    title: <Trans>Satisfaction guaranteed</Trans>,
    description: (
      <Trans>
        We guarantee our calculations are 100% accurate and that we'll find you your maximum refund.
      </Trans>
    ),
  },
  {
    icon: IconConfetti,
    title: <Trans>Confetti</Trans>,
    description: <Trans>We also have confetti.</Trans>,
  },
]

const Feature = ({
  icon: Icon,
  title,
  description,
}: {
  icon: ElementType
  title: ReactNode
  description: ReactNode
}) => (
  <div>
    <ThemeIcon variant="light" size={40} radius={40}>
      <Icon size={18} />
    </ThemeIcon>
    <Text mt="sm" mb="xs">
      {title}
    </Text>
    <Text c="dimmed" lh={1.6}>
      {description}
    </Text>
  </div>
)

const FeaturesGrid = () => {
  const features = featureData.map((feature, index) => <Feature {...feature} key={index} />)

  return (
    <PageContainer my="4rem">
      <Title order={2} fz="2rem" lh="2rem" fw={600} ta="center" mb="md">
        <Trans>We've got you covered</Trans>
      </Title>
      <Container size={800}>
        <Text ta="center">
          <Trans>Better Tax has everything you need to file your own return.</Trans>
        </Text>
      </Container>

      <SimpleGrid
        my="4rem"
        cols={{ base: 1, sm: 2, md: 3 }}
        spacing={{ base: "xl", md: 50 }}
        verticalSpacing={{ base: "xl", md: 50 }}
      >
        {features}
      </SimpleGrid>
    </PageContainer>
  )
}
